import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { ValidationObserver } from 'vee-validate'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import IntegrationInfo from '@/modules/common/components/mixins/integrationInfo'
import { mapState } from 'vuex'

@Component({
  name: 'GtrEventEditSyncView',
  filters: {
    lowercase (value) {
      return value.toLowerCase()
    }
  },
  computed: {
    ...mapState('syncs', ['syncs', 'active_syncs', 'sample_sync_data', 'sync_history']),
    ...mapState('event', ['participant_fields', 'session_fields', 'session_attendee_fields']),
    ...mapState('security', ['currentUser']),
    ...mapState('option', ['option_groups'])
  }
})
export default class GtrEventEditSyncView extends mixins(GtrSuper, IntegrationInfo) {
  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>;

  currentUser!: Record<string, any>;

  option_groups!: Array<Record<string, any>>;

  session_attendee_selection_options: Array<Record<string, any>> = [];

  hook_modal = false;

  webhookUrl = `${process.env.VUE_APP_API_URL}event/${this.$route.params.event_uuid}/sync/${this.$route.params.sync_uuid}/aventri-webhook`;

  webhookUrlBizzabo = `${process.env.VUE_APP_API_URL}event/${this.$route.params.event_uuid}/sync/${this.$route.params.sync_uuid}/bizzabo-webhook`;

  data () {
    return {
      webhookUrl: `${process.env.VUE_APP_API_URL}event/${this.$route.params.event_uuid}/sync/${this.$route.params.sync_uuid}/aventri-webhook`,
      webhookUrlBizzabo: `${process.env.VUE_APP_API_URL}event/${this.$route.params.event_uuid}/sync/${this.$route.params.sync_uuid}/bizzabo-webhook`,
      current_sync_data: {
        type: null,
        send_welcome_email: false,
        base_update_on: null,
  	    status: false,
  	    run_frequency: 10,
        sync_map: [],
        sync_fields: {}
  	  },
      loading: true,
      width: window.innerWidth,
	    sync_type_items_for_select: [],
      all_sync_hash: {},
	    sync_map_modal: false,
	    sync_history_modal: false,
	    external_fields: [],
	    current_sync_sample_data: [],
      tables: { sync_history: { headers: [{ text: 'Status', value: 'status' }, { text: 'Success', value: 'was_success' }, { text: 'Duration (Seconds)', value: 'duration' }, { text: 'Started', value: 'created_at' }, { text: 'Ended', value: 'updated_at' }, { text: 'RequestId', value: 'request_id' }, { text: 'Export Only', value: 'did_export' }, { text: 'Result', value: 'raw_result' }], items: [] } },
      status_items_for_select: [
        {
          text: 'On',
          value: 1
        },
        {
          text: 'Off',
          value: 0
        }
	    ],
      welcome_email_items_for_select: [
        {
          text: 'On',
          value: 1
        },
        {
          text: 'Off',
          value: 0
        }
	    ],
      // uniqueIdentifier: [
      //   {
      //     label: 'Email',
      //     value: 'email'
      //   },
      //   {
      //     label: 'External ID',
      //     value: 'external_id'
      //   }
      // ],
	    run_frequency_items_for_select: [
        {
          label: '10 minutes',
          value: 10
        },
        {
          label: '30 minutes',
          value: 30
        },
        {
          label: '60 minutes',
          value: 60
        },
        {
          label: '6 hours',
          value: 360
        },
        {
          label: '24 hours',
          value: 1440
        }
      ],
      participant_fields_items_for_select: [],
      session_fields_items_for_select: [],
      current_sync_table: '',
      push_or_pull: ''
    }
  }

  showHookModal () {
    this.hook_modal = true
  }

  hideHookModal () {
    this.hook_modal = false
  }

  onError (): void {
    Container.get(Notification).error('Error copying URL to clipboard')
  }

  onCopy (): void {
    Container.get(Notification).success('URL copied to clipboard')
  }

  handleSyncMapModalOpen () {
	  this.$data.sync_map_modal = true
  }

  handleSyncMapModalClose () {
	  this.$data.sync_map_modal = false
  }

  handleSyncHistoryModalOpen () {
	  this.$data.sync_history_modal = true
    this.$store.dispatch('syncs/getSyncHistory', { event_uuid: this.$route.params.event_uuid, sync_uuid: this.$route.params.sync_uuid })
  }

  startSync () {
    this.$store.dispatch('syncs/startSync', { event_uuid: this.$route.params.event_uuid, sync_uuid: this.$route.params.sync_uuid })
    Container.get(Notification).success('Sync successfully started.')
  }

  refreshSync () {
    this.$store.dispatch('syncs/getSyncHistory', { event_uuid: this.$route.params.event_uuid, sync_uuid: this.$route.params.sync_uuid })
  }

  openGraylog (item) {
    window.open('http://graylog.gtrnow.com:9000/search?q=request_id%3A%22' + item.request_id + '%22&rangetype=relative&from=3600', '_blank')
  }

  startExport () {
    this.$store.dispatch('syncs/startExport', { event_uuid: this.$route.params.event_uuid, sync_uuid: this.$route.params.sync_uuid })
    Container.get(Notification).success('Export started.')
  }

  handleSyncHistoryModalClose () {
	  this.$data.sync_history_modal = false
  }

  updateStatus () {
    this.$data.current_sync_data.status = !this.$data.current_sync_data.status
  }

  updateEmail () {
    this.$data.current_sync_data.send_welcome_email = !this.$data.current_sync_data.send_welcome_email
  }

  async mounted () {
    try {
      const { event_uuid } = this.$route.params
      await this.$store.dispatch('syncs/getSyncs')
      await this.$store.dispatch('event/getParticipantFields', event_uuid)
      await this.$store.dispatch('event/getSessionFields', event_uuid)
      await this.$store.dispatch('event/getSessionAttendeeFields', event_uuid)
      await this.$store.dispatch('syncs/getActiveSyncs', event_uuid)
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        sync_uuid: this.$route.params.sync_uuid
      }
      await this.$store.dispatch('syncs/getSampleSyncData', payload)
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async submit () {
    const payload = {
      event_uuid: this.$route.params.event_uuid,
      data: this.$data.current_sync_data,
	    sync_uuid: this.$route.params.sync_uuid
    }
    if (payload.data.sync_map && payload.data.sync_map.length > 0) {
      payload.data.map = payload.data.sync_map.filter(obj => {
        // clearable prop on v-select sets v-model to null but empty internal fields are an empty string ''
        if (obj.field === null) {
          obj.field = ''
        }
        return obj.field !== ''
      })
    } else {
      payload.data.map = []
    }
    delete payload.data.sync_map
    try {
      this.$data.loading = true
      await this.$store.dispatch('syncs/updateSync', payload)
      Container.get(Notification).success('Sync successfully edited.')
      await this.$store.dispatch('syncs/getActiveSyncs', this.$route.params.event_uuid)
      await this.$store.dispatch('syncs/getSampleSyncData', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get uniqueIdentifier () {
    switch (this.$data.current_sync_table) {
      case 'participants':
        return [
          {
            label: 'Email',
            value: 'email'
          },
          {
            label: 'External ID',
            value: 'external_id'
          }
        ]
      case 'sessions':
        return [
          {
            label: 'External ID',
            value: 'external_id'
          }
        ]
      case 'session_attendees':
      default:
        return [
          {
            label: 'Flag',
            value: 'flag'
          }
        ]
    }
  }

  getLogo () {
    for (let i = 0; i < this.$data.integrationCardInfo.length; i++) {
      if (
        this.$data.integrationCardInfo[i].name.toLowerCase() === this.$data.current_sync_data.type ||
        this.$data.integrationCardInfo[i].name.toLowerCase() === this.$data.current_sync_data.type.slice(0, this.$data.current_sync_data.type.length - 4)
      ) {
        return this.$data.integrationCardInfo[i].logo
      }
    }
  }

  getName () {
    for (let i = 0; i < this.$data.integrationCardInfo.length; i++) {
      if (
        this.$data.integrationCardInfo[i].name.toLowerCase() === this.$data.current_sync_data.type ||
        this.$data.integrationCardInfo[i].name.toLowerCase() === this.$data.current_sync_data.type.slice(0, this.$data.current_sync_data.type.length - 4)
      ) {
        return this.$data.integrationCardInfo[i].display
      }
    }
  }

  getAlt () {
    for (let i = 0; i < this.$data.integrationCardInfo.length; i++) {
      if (
        this.$data.integrationCardInfo[i].name.toLowerCase() === this.$data.current_sync_data.type ||
        this.$data.integrationCardInfo[i].name.toLowerCase() === this.$data.current_sync_data.type.slice(0, this.$data.current_sync_data.type.length - 4)
      ) {
        return this.$data.integrationCardInfo[i].alt
      }
    }
  }

  get current_sync_type () {
    return this.$data.current_sync_data.type + ' ' + this.$data.current_sync_table
  }

  get currentUserAccessLevel () {
    return this.currentUser.access_level
  }

  @Watch('syncs')
  onSyncChange (payload: any) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        this.$data.sync_type_items_for_select.push({
          text: payload[i].displayName,
          value: payload[i].displayName
        })
        Vue.set(this.$data.all_sync_hash, payload[i].displayName, payload[i])
      }
    }
  }

  @Watch('active_syncs')
  onActiveSyncChange (payload) {
    if (payload.length) {
      const current_sync_data = payload.filter(sync => sync.uuid === this.$route.params.sync_uuid).pop()
      this.$data.current_sync_table = current_sync_data.table
      this.$data.push_or_pull = current_sync_data.push_or_pull
      Object.keys(current_sync_data).filter(key => key in this.$data.current_sync_data).forEach(key => {
        this.$data.current_sync_data[key] = current_sync_data[key]
      })
      this.$data.current_sync_data.sync_map = current_sync_data.map ?? []
    }
  }

  @Watch('sync_history')
  onSyncHistoryChange (payload) {
    if (payload.length) {
      const payloadAll = payload
      this.$data.tables.sync_history.items = []
      for (let i = (payloadAll.length - 1); i >= 0; i--) {
        const payloadCur = payloadAll[i]
        payloadCur.did_export = payloadCur.export ? 'Yes' : 'No'
        payloadCur.was_success = payloadCur.success ? 'Yes' : 'No'
        payloadCur.raw_result = JSON.stringify(payloadCur.result)
        this.$data.tables.sync_history.items.push(payloadCur)
      }
    }
  }

  @Watch('participant_fields')
  onParticipantFieldsChange (payload) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        // don't add fields that are tied to option groups.
        if (payload[i].option_group_uuid) continue
        if (!this.excludedParticipantImportFields.includes(payload[i].field)) {
          this.$data.participant_fields_items_for_select.push({
            text: payload[i].label,
            value: payload[i].field,
            used: payload[i].used,
            custom_field: payload[i].custom_field
          })
        }
      }
    }
  }

  @Watch('session_fields')
  onSessionFieldsChange (payload) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        this.$data.session_fields_items_for_select.push({
          text: payload[i].label,
          value: payload[i].field,
          used: payload[i].used,
          custom_field: payload[i].custom_field
        })
      }
    }
  }

  @Watch('session_attendee_fields')
  onSessionAttendeeFieldsChange (payload) {
    if (payload.length) {
      for (const { label, field, used, custom_field } of payload) {
        this.session_attendee_selection_options.push({
          text: label,
          value: field,
          used: used,
          custom_field: custom_field
        })
      }
    }
  }

  private addOptionsGroups (participant_fields_items: any) {
    this.option_groups.forEach((group) => {
      participant_fields_items.push({
        text: `Option Group: ${group.name}`,
        value: group.uuid,
        used: (group.on_field !== null)
      })
    })
    return participant_fields_items
  }

  get fieldItemsForSelectToUse () {
    switch (this.$data.current_sync_table) {
      case 'participants':
        return this.addOptionsGroups(this.$data.participant_fields_items_for_select)
      case 'sessions':
        return this.$data.session_fields_items_for_select
      case 'session_attendees':
        return this.session_attendee_selection_options
      default:
        return []
    }
  }

  @Watch('sample_sync_data')
  onSampleSyncDataChange (payload: any) {
    if (payload.length) {
      const dataSlice = payload.slice(1)
	    this.$data.external_fields = payload[0]
	    this.$data.current_sync_sample_data = dataSlice.length ? dataSlice : []
      const old_sync_map_data = this.$data.current_sync_data.sync_map
      const old_sync_map_hash = {}
      for (let i = 0; i < old_sync_map_data.length; i++) {
        old_sync_map_hash[old_sync_map_data[i].external_field] = old_sync_map_data[i].field
      }
      this.$data.current_sync_data.sync_map = []
      for (let i = 0; i < payload[0].length; i++) {
        // if (!externalMapFieldsAlreadySetHash.includes(payload[0][i])) {
        this.$data.current_sync_data.sync_map.push({
          field: old_sync_map_hash.hasOwnProperty(payload[0][i]) ? old_sync_map_hash[payload[0][i]] : '',
          external_field: payload[0][i],
          valueType: 'field'
        })
        // }
      }
    }
  }
}
