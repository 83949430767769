import { Component, Vue } from 'vue-property-decorator'
import { Service } from 'typedi'

@Service()
@Component({
  name: 'IntegrationInfo'
})
export default class IntegrationInfo extends Vue {
  integrationCardInfo: any[] = [
    {
      name: 'Hubspot',
      display: 'HubSpot',
      desc: 'A CRM platform with tools and integrations for marketing, sales, content management, and customer service.',
      logo: require('@/assets/img/integrations/hubspot-logo.svg'),
      alt: 'Hubspot logo',
      push: ['participants'],
      pull: []
    },
    {
      name: 'Swapcard',
      display: 'Swapcard',
      desc: 'Virtual event matchmaking platform helping people find relevant content using articial intelligence.',
      logo: require('@/assets/img/integrations/swapcard-logo.svg'),
      alt: 'Swapcard logo',
      push: [],
      pull: ['participants']
    },
    {
      name: 'Pathable',
      display: 'Pathable',
      desc: 'Virtual event platform for attendees, exhibitors, sponsors, and speakers with webinars and 2-way video.',
      logo: require('@/assets/img/integrations/pathable-logo.svg'),
      alt: 'Pathable logo',
      push: ['participants'],
      pull: ['participants']
    },
    // {
    //   name: 'Marketo',
    //   desc: 'Marketing automation software for account-based marketing and other services including SEO and content creation.',
    //   logo: require('@/assets/img/integrations/marketo-logo.svg'),
    //   alt: 'Marketo logo',
    //   push: [],
    //   pull: []
    // },
    {
      name: 'Aventri',
      display: 'Aventri',
      desc: 'Event management platformwith trackable multi-session capabilities and audience engagement features.',
      logo: require('@/assets/img/integrations/aventri-logo.svg'),
      alt: 'Aventri logo',
      push: [],
      pull: ['participants']
    },
    {
      name: 'Bizzabo',
      display: 'Bizzabo',
      desc: 'Event management platform with event registration and fully branded mobile event apps with live broadcasts.',
      logo: require('@/assets/img/integrations/bizzabo-logo.svg'),
      alt: 'Bizzabo logo',
      push: [],
      pull: ['participants']
    },
    {
      name: 'Pardot',
      display: 'Pardot',
      desc: 'Marketing automation software with lead management tools for sales teams.',
      logo: require('@/assets/img/integrations/salesforce-pardot-logo.svg'),
      alt: 'Pardot logo',
      push: ['participants'],
      pull: []
    },
    {
      name: 'Eventbrite',
      display: 'Eventbrite',
      desc: 'Event management and ticketing service allowing users to browse, create, and promote local events.',
      logo: require('@/assets/img/integrations/eventbrite-logo.svg'),
      alt: 'Eventbrite logo',
      push: [],
      pull: ['participants']
    },
    {
      name: 'Eventsquid',
      display: 'Eventsquid',
      desc: 'Event registration and management software with website and mobile app creation.',
      logo: require('@/assets/img/integrations/eventsquid-logo.svg'),
      alt: 'Eventsquid logo',
      push: [],
      pull: ['participants']
    },
    // {
    //   name: 'Yourmembership',
    //   desc: 'Member management software for associations, nonprofits, and other member-based organizations.',
    //   logo: 'YM',
    //   alt: 'Yourmembership logo',
    //   push: [],
    //   pull: []
    // },
    {
      name: 'Configio',
      display: 'Configio',
      desc: 'Event registration and management software for content, learning, relationships, memberships, and email marketing.',
      logo: require('@/assets/img/integrations/configio-logo.svg'),
      alt: 'Configio logo',
      push: [],
      pull: ['participants']
    },
    {
      name: 'Mctrade',
      display: 'MC Trade',
      desc: 'A web-based membership management software for organizations, associations, chambers, and organization-based memberships',
      logo: require('@/assets/img/integrations/mctrade-logo.svg'),
      alt: 'MC Trade logo',
      push: [],
      pull: ['participants']
    }
  ]
}
